import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { submitContactForm } from "../../services";
import { useHistory } from 'react-router-dom';

function HeroComponent(props) {
    const {
        bigText,
        smallText,
        buttonText,
        page,
    } = props;

    const history = useHistory();
    const [contactFormFields, setContactFormFields] = useState({
        name: "",
        email: "",
        phone: "",
        projectdescription: "",
    });
    const [selectedGame, setSelectedGame] = useState(null);
    const [step, setStep] = useState(1);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setContactFormFields({
            ...contactFormFields,
            [name]: value,
        });
    };

    const submitFormHandler = async (e) => {
        e.preventDefault();

        const payload = {
            fields: [
                {
                    name: "email",
                    value: contactFormFields.email,
                },
                {
                    name: "name",
                    value: contactFormFields.name,
                },
                {
                    name: "phone",
                    value: contactFormFields.phone,
                },
                {
                    name: "projectdescription",
                    value: contactFormFields.projectdescription,
                },
                {
                    name:"Game Interested In",
                    value:selectedGame,
                }
            ],
        };
        const res = await submitContactForm(payload);
        if (res?.inlineMessage === "Thanks for submitting the form.") {
            setContactFormFields({
                name: "",
                email: "",
                phone: "",
                projectdescription: "",
            });
            history.push("/thankyou-page");
        } else {
            console.log("Error submitting the form");
        }
    };

    const handleGameSelect = (game) => {
        setSelectedGame(game);
    };

    const handleNextStep = () => {
        if (selectedGame) {
            setStep(2); // Move to contact form step
        }
    };

    return (
        <>
            {page === "landing-page" ? (
                <Container className="hero-container-landing-page-bg hero-container">
                    <div className="hero-container-inner">
                        <div className="landing-page-hero-section">
                            <p className="lp-big-text">Unleash Your Digital</p>
                            <p className="lp-big-text mt--15">Dreams with <span className="red">Digital Crew</span></p>
                            <br />
                            <span className="lp-small-text mt--15">Transform Your Ideas with Our Expert Solutions</span>
                        </div>

                        <div className="lp-contact-form">
                            {step === 1 ? (
                                <>
                                    <h5>What games are you interested in developing?</h5>
                                    <div className="game-options">
                                        {["Rummy", "Poker", "Fantasy", "Multi-Gaming Platform", "Sports Betting & Casino", "Others"].map((game) => (
                                            <Button
                                                key={game}
                                                className={`mt-2`}
                                                onClick={() => handleGameSelect(game)}
                                                style={{
                                                    display: 'block',
                                                    margin: '10px auto',
                                                    width: '300px',
                                                    backgroundColor: selectedGame === game ? '#D97C7B' : '#F69C9B',
                                                    borderRadius: '25px',
                                                    border: 'none',
                                                    padding: '10px',
                                                    textAlign: 'center',
                                                    color: 'white'
                                                }}
                                            >
                                                {game}
                                            </Button>
                                        ))}
                                    </div>

                                    {/* Next button to move to contact form */}
                                    <Button
                                        className="mt-3"
                                        onClick={handleNextStep}
                                        disabled={!selectedGame} // Disable Next button until a game is selected
                                        style={{
                                            display: 'block',
                                            margin: '20px auto', // Centering the button
                                            width: '200px',      // Set the button width
                                            padding: '10px',     // Adjust padding for spacing
                                            textAlign: 'center',
                                            backgroundColor: '#F44336',
                                            color: 'white',
                                            border: 'none',
                                            borderRadius: '5px'
                                        }}
                                    >
                                        Next
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <h2>Contact Us</h2>
                                    <p>Get Response Within 24 Hours</p>
                                    <form onSubmit={submitFormHandler}>
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="Enter Your Name"
                                            value={contactFormFields.name}
                                            onChange={handleChange}
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="mobile"
                                            placeholder="Enter Mobile Number"
                                            value={contactFormFields.mobile}
                                            onChange={handleChange}
                                            required
                                        />
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Enter Your Email"
                                            value={contactFormFields.email}
                                            onChange={handleChange}
                                            required
                                        />
                                        <textarea
                                            name="projectdescription"
                                            placeholder="Write Your Message"
                                            value={contactFormFields.projectdescription}
                                            onChange={handleChange}
                                            required
                                        />
                                        <button type="submit">SUBMIT</button>
                                    </form>
                                </>
                            )}
                        </div>
                    </div>
                </Container>
            ) : page === "thankyou-page" ? (
                <Container className={`hero-container-${page}-bg hero-container`}>

                </Container>
            ) : (
                <Container className={`hero-container-${page}-bg hero-container`}>
                    {page === "home" && (
                        <div className="pic-wrapper">
                            <figure className="pic-1"></figure>
                            <figure className="pic-2"></figure>
                            <figure className="pic-3"></figure>
                            <figure className="pic-4"></figure>
                            <figure className="pic-5"></figure>
                            <figure className="pic-6"></figure>
                        </div>
                    )}
                    <div className="hero-container-inner">
                        <div className="hero-section1">
                            <div className="section-1-inner">
                                <span
                                    className="hero-bigText"
                                    style={{ textAlign: page === "home" && "left" }}
                                    dangerouslySetInnerHTML={{ __html: bigText }}
                                ></span>
                                {page === "portfolio" ? (
                                    <>
                                        <div className="portfolio-hero-section">
                                            <div className="info-section">
                                                <p className="big-number">10+</p>
                                                <p className="red-text">Mobile Apps</p>
                                            </div>
                                            <div className="info-section">
                                                <p className="big-number">81+</p>
                                                <p className="red-text">Web Apps</p>
                                            </div>
                                            <div className="info-section">
                                                <p className="big-number">21+</p>
                                                <p className="red-text">Services</p>
                                            </div>
                                        </div>
                                    </>
                                ) : page === "aboutus" ? (
                                    <>
                                        <p
                                            className="hero-smallText"
                                            dangerouslySetInnerHTML={{ __html: smallText }}
                                        ></p>
                                    </>
                                ) : page === "games" ? (
                                    <>
                                        <p
                                            className="hero-smallText"
                                            dangerouslySetInnerHTML={{ __html: smallText }}
                                        ></p>
                                    </>
                                ) : (
                                    <>
                                        <p
                                            className="hero-smallText"
                                            dangerouslySetInnerHTML={{ __html: smallText }}
                                        ></p>
                                        <Button
                                            className={
                                                page === "home"
                                                    ? "hero-button-red-to-grey"
                                                    : "hero-button-grey-to-red"
                                            }
                                        >
                                            <a href="/contact-us">
                                                {buttonText}{" "}
                                                {page === "home" && <i className="icon play-btn" />}
                                            </a>
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </Container>
            )}
        </>
    );
}

export default HeroComponent;
